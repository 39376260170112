body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.logo {
  height: 48px;
  margin: 16px;
  text-align: center;
}

.logo>img {
  max-height: 100%;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.headerMenu-IconBurger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 48px 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.headerMenu-Icon {
  font-size: 18px;
  line-height: 64px;
  padding: 0 12px;
  cursor: pointer;
  transition: color 0.3s;
}

.headerMenu-Icon:hover {
  color: #1890ff;
}

.right {
  float: right;
  height: 100%;
  margin-left: auto;
  overflow: hidden;
}

.ant-menu-sub .ant-menu-item {
  padding-left: 32px!important;
}

.page-header-container {
  background-color: rgba(245, 245, 245, 0.4);
  padding: 8px;
  margin-bottom: 32px;
}

.page-header {
  border-bottom: '1px solid rgb(235, 237, 240)';
  padding: 0;
  padding-bottom: 24;
  margin-bottom: 24;
}

.site-page-header-ghost-wrapper {
  background-color: #fafafa;
  padding: 24px;
  margin-bottom: 24px;
}


.actions-column {
  width: 230px;
}

.mr-8 {
  margin-right: 8px;
}

/**************************** LOGIN PAGE ****************************/
#login-card {
  -webkit-box-shadow: -3px -3px 10px 5px rgba(184,184,184,0.75);
  -moz-box-shadow: -3px -3px 10px 5px rgba(184,184,184,0.75);
  box-shadow: -3px -3px 10px 5px rgba(184,184,184,0.75);
}

#loginPageWrapper {
  min-height: 100vh;
  background-repeat: repeat;
}

#loginPageWrapper #logo {
  max-height: 150px;
}

#loginPageWrapper #loginText {
  color: #001529;
  font-size: 32px;
  font-weight: 600;
}

#loginPageWrapper #loginError {
  margin-bottom: 16px;
  margin-top: 16px;
}

#loginPageWrapper #loginButton {
  float: right;
}

#loginPageWrapper .loginInputIcon {
  color: rgba(0, 0, 0, 0.25);
}

/**************************** MAIN PAGE ****************************/
#mainPageLayout {
  min-height: 100vh!important;
}

html { height: 100%!important; overflow-y: scroll!important; }
body { min-height: 100%!important }

#mainPageLayout #topMenu {
  height: calc(100% - 65px);
  border-right: 0;
}

#mainPageLayout #topHeader {
  background: #ffffff;
  padding: 0;
}

#mainPageLayout #topHeaderHotels {
  width: 200px;
  margin-left: 36px;
  margin-right: 8px;
}

#mainPageLayout #topHeaderRightElements {
  margin-right: 16px;
}

#mainPageLayout #mainPageContent {
  background: #fff;
  padding: 24px;
  margin: 16px 16px;
  min-height: 280px;
}

/**************************** Component: AvatarDropdown ****************************/
.userAvatarDropdown {
  vertical-align: middle!important;
}
.userAvatarDropdownText {
  color: black;
  margin-left: 8px;
}

.userAvatarDropdownButton {
  all: unset;
}

/**************************** Table Ordering ****************************/
.table-ordering {
  border: 1px solid #eeeeee;
  width: 100%;
}

.table-ordering tr {
  border: 1px solid #eeeeee;
}

.table-ordering th, .table-ordering td {
  border: 1px solid #eeeeee;
  padding: 4px 8px;
}

.finmatek-chart-container {
  -webkit-box-shadow: 0px 5px 5px 0px #B7B7B7;
  box-shadow: 0px 5px 5px 0px #B7B7B7;
  border: 1px solid #fafafa;
  padding: 16px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.finmatek-chart-container > h4 {
  text-align: center;
  font-weight: bold;
}

.finmatek-chart {
  width: 100%;
  height: 130px;
}

.finmatek-charts-form {
  display: flex;
  justify-content: center;
}

.icon-error :before {
  width: 100%;
  background-color: red;
}

.center-rangepicker input {
  text-align: center!important;
}
