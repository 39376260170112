body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-lock-screen:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f023";
  color: black;
}

.icon-lock-screen {
  color: black!important;
}

.icon-error:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f071";
  color: red;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: rgb(27,58,111);
  background: #1b3a6f!important;
}

.nav-text span,
.ant-menu-submenu-title span {
  color: white!important;
  font-size: 14px!important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffaa5a!important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span {
  font-weight: bold;
  color: #1b3a6f!important;
}

.logo {
  margin: 0px!important;
  padding: 8px!important;
  height: 64px!important;
  background: white!important;
}

.logo img {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.ag-theme-balham .ag-row,
.ag-theme-balham .ag-header-cell {
  font-family: Consolas, monaco, monospace!important;
}

.icon-info:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f05a";
  color: black;
}